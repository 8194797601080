import React, { Suspense } from 'react'
import { Routes, Route, Outlet, Navigate } from 'react-router-dom'
import FallbackSpinner from './@core/components/spinner'

import AuthGuard from 'src/@core/components/auth/AuthGuard'
import UserLayout from './layouts/UserLayout'
import BlankLayout from './@core/layouts/BlankLayout'
// import BlankLayoutWithAppBar from './@core/layouts/BlankLayoutWithAppBar'
import AclGuard from './@core/components/auth/AclGuard'
import GuestGuard from './@core/components/auth/GuestGuard'
import { defaultACLObj } from './configs/acl'
import AuthLayout from './layouts/AuthLayout'
import MemberDetail from './pages/member-detail'

const LoginPage = React.lazy(() => import('./pages/login'))
const RegisterPage = React.lazy(() => import('./pages/register'))
const ServiceFormPage = React.lazy(() => import('./pages/serviceForm'))
const ForgotPassword = React.lazy(() => import('./pages/forgotPassword'))
const VerifyOTPPage = React.lazy(() => import('./pages/verifyOTP'))
const ResetPassword = React.lazy(() => import('./pages/resetPassword'))
const HomePage = React.lazy(() => import('./pages/home'))
const SubmitClaimPage = React.lazy(() => import('./pages/submitClaim'))

const MyProfile = React.lazy(() => import('./pages/my/profile'))
const EditProfilePage = React.lazy(() => import('./pages/my/editProfile'))
const MyReimbursements = React.lazy(() => import('./pages/my/reimbursement'))
const BonusIncentive = React.lazy(() => import('./pages/bonus-incentive'))
const ChangePassword = React.lazy(() => import('./pages/my/profile/change-password'))
const Search = React.lazy(() => import('./pages/search'))

const Page401 = React.lazy(() => import('./pages/401'))
const Page404 = React.lazy(() => import('./pages/404'))

const Guard = ({ children, authGuard, guestGuard }) => {
  if (guestGuard) {
    return <GuestGuard fallback={<FallbackSpinner />}>{children}</GuestGuard>
  } else if (!guestGuard && !authGuard) {
    return <>{children}</>
  } else {
    return <AuthGuard fallback={<FallbackSpinner />}>{children}</AuthGuard>
  }
}

function App() {
  const aclAbilities = defaultACLObj

  return (
    <Suspense fallback={<FallbackSpinner />}>
      <AclGuard aclAbilities={aclAbilities}>
        <Routes>
          <Route element={<BlankLayout><Outlet /></BlankLayout>}>
            <Route path='/401' element={<Page401 />} />
            <Route path='/404' element={<Page404 />} />

            <Route element={<AuthLayout><Outlet /></AuthLayout>}>
              <Route element={<Guard guestGuard><Outlet /></Guard>}>
                <Route path='/login' element={<LoginPage />}></Route>
                <Route path='/register' element={<RegisterPage />}></Route>
                <Route path='/service' element={<ServiceFormPage />}></Route>
                <Route path='/forgot-password' element={<ForgotPassword />}></Route>
                <Route path='/reset-password' element={<ResetPassword />}></Route>
                <Route path='/verify-otp' element={<VerifyOTPPage />}></Route>
              </Route>
            </Route>
          </Route>

          <Route path='/' element={<Navigate to='/provider' replace />} />

          <Route element={<UserLayout><Outlet /></UserLayout>}>
            <Route element={<Guard authGuard><Outlet /></Guard>}>
              <Route path='/provider' element={<HomePage />} />
              <Route path='/provider/member-detail/:id' element={<MemberDetail />} />
              <Route path='/provider/submit-claim' element={<SubmitClaimPage />} />
              <Route path='/bonus-incentive' element={<BonusIncentive />} />
              <Route path='/my/reimbursements' element={<MyReimbursements />} />
              <Route path='/my/profile' element={<MyProfile />} />
              <Route path='/my/profile/edit-profile' element={<EditProfilePage />} />
              <Route path='/my/profile/change-password' element={<ChangePassword />} />
              <Route path='/search' element={<Search />} />
            </Route>
          </Route>

          {/* If no route found redirect it to --> /404 */}
          <Route path='*' element={<Navigate to='/404' replace />} />

        </Routes>
      </AclGuard>
    </Suspense>
  );
}

export default App;
