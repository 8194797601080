export const PublicRoutes = [];
export const ProfileSupported = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'video/mp4', 'video/mov', 'video/avi', 'video/mkv']
export const ProfileSupportedDropzone = { 'image/jpeg': [], 'image/png': [], 'image/jpg': [], 'image/gif': [], 'video/mp4': [], 'video/mov': [], 'video/avi': [], 'video/mkv': [], 'application/pdf': [] }
export const DocumentSupported = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf']
export const preExistingConditions = [
    { value: 'HIV/AIDS', label: 'HIV/AIDS' },
    { value: 'cancer', label: 'Cancer' },
    { value: 'diabetes', label: 'Diabetes' },
    { value: 'high_blood_pressure', label: 'High Blood Pressure' },
]

export const weekdays = [
    { index: 1, label: "Sunday", value: 'sunday' },
    { index: 2, label: "Monday", value: 'monday' },
    { index: 3, label: "Tuesday", value: 'tuesday' },
    { index: 4, label: "Wednesday", value: 'wednesday' },
    { index: 5, label: "Thursday", value: 'thursday' },
    { index: 6, label: "Friday", value: 'friday' },
    { index: 7, label: "Saturday", value: 'saturday' },
];


export const PlanStatus = [
    { index: 1, label: "No Plan", value: "no_plan", color: 'grey', bg: '#e3e3e3' },
    { index: 2, label: "Active", value: "active", color: '#1ea922', bg: '#d6ffcf' },
    { index: 3, label: "Waiting", value: "waiting", color: '#ff8400', bg: '#ffdcb7' },
    { index: 4, label: "Covered", value: "covered", color: 'purple', bg: '#dbc0db' },
    { index: 5, label: "Suspended", value: "suspended", color: 'red', bg: '#ffbbbb' },
]