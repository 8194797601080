// ** Icon imports
import ProfileIcon from 'src/@core/components/icons/ProfileIcon'
import MemberIcon from 'src/@core/components/icons/MemberIcon'
import ReimbursementIcon from 'src/@core/components/icons/ReimbursementIcon'
import BonusIcon from 'src/@core/components/icons/BonusIcon'

const navigation = () => {
  return [
    {
      title: 'Members',
      icon: MemberIcon,
      path: '/provider'
    },
    {
      title: 'My Reimbursement',
      icon: ReimbursementIcon,
      path: '/my/reimbursements'
    },
    {
      title: 'My Profile',
      icon: ProfileIcon,
      path: '/my/profile'
    },
    {
      title: 'Bonus Incentive',
      icon: BonusIcon,
      path: '/bonus-incentive'
    }
  ]
}

export default navigation
