import React from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
  FormControl,
  MenuItem,
  Select,
  FormLabel,
  ListItemText
} from '@mui/material'
import useCustomTranslation from 'src/@core/hooks/useCustomTranslation'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useTranslation } from 'react-i18next'

function ChangeLanguageDialog({ open, toggle }) {
  const translation = useCustomTranslation()

  const handleToggle = () => {
    toggle()
  }
  const languages = [
    { code: 'en', lang: 'English' },
    { code: 'fr', lang: 'French' },

  ]
  // Change language
  const { i18n } = useTranslation()
  const changeLanguage = lng => {
    i18n.changeLanguage(lng)
  }

  return (
    <Dialog fullWidth maxWidth={'xs'} open={open} onClose={toggle} PaperProps={{ sx: { borderRadius: '24px' } }}>
      <Card
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          py: '20px',
          px: '15px'
        }}
      >
        <DialogTitle variant='h6' fontWeight={600}>
          {translation.translate(`Change Language`)}
        </DialogTitle>

        <FormControl fullWidth sx={{ padding: '10px' }}>
          <FormLabel id='demo-simple-select-label' style={{ fontSize: '18px' }}>
          {translation.translate(`Select your Language`)}
          </FormLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            //   value={categorySelected}
            //   onChange={handleAgeChangeCategorySelect}
            variant='outlined'
            IconComponent={ExpandMoreIcon}
          >
            {languages.map(lng => {
              return (
                <MenuItem key={lng.code} value={lng.code} onClick={() => changeLanguage(lng.code)}>
                  <ListItemText primary={lng.lang} sx={{ color: '#344054' }} />
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>

        <DialogActions sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <Link style={{ textDecoration: 'none' }}>
            <Button onClick={() => handleToggle()} variant='contained' sx={{ padding: '12px 30px 12px 30px' }}>
              <Typography variant='fm-p3' fontWeight={600}>{translation.translate('SaveChanges')}</Typography>
            </Button>
          </Link>
        </DialogActions>
      </Card>
    </Dialog>
  )
}

export default ChangeLanguageDialog