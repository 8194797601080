export const API_BASE_URL = 'https://dev.api.famyll.com/provider-ms/api/v1/'
// export const API_BASE_URL = 'https://062a-2405-201-2046-c806-2ef-531a-f20-1322.ngrok-free.app/planowner-ms/api/v1/'

export const ApiEndpoints = {
  AUTH: {
    veryfy: `${API_BASE_URL}auth/verify`,
    register: `${API_BASE_URL}auth/sign-up`,
    login: `${API_BASE_URL}auth/sign-in`,
    forgotPassword: `${API_BASE_URL}auth/forgot-password`,
    verifyOtp: `${API_BASE_URL}auth/verify-otp`,
    resendOtp: `${API_BASE_URL}auth/resend-otp`,
    resetPassword: `${API_BASE_URL}auth/reset-password`,
    changePassword: `${API_BASE_URL}auth/change-password`,
    me: `${API_BASE_URL}auth/me`,
    notifications: `${API_BASE_URL}auth/notification`,
    editProfile: `${API_BASE_URL}auth/edit`
  },
  GET_COUNTRY_STATE: {
    country: `https://countriesnow.space/api/v0.1/countries/flag/unicode`,
    state: `https://countriesnow.space/api/v0.1/countries/states`,
    city: `https://countriesnow.space/api/v0.1/countries/state/cities`
  },
  GET_REGION: {
    country: `https://api.countrystatecity.in/v1/countries`,
    state: `https://api.countrystatecity.in/v1/countries/`,
  },
  PROVIDER: {
    planService: `${API_BASE_URL}provider/plan-services`,
    providerTypeList: `${API_BASE_URL}provider/type`
  },
  MEMBERS: {
    memberList: `${API_BASE_URL}/member/`,
    memberDetails: (id) => `${API_BASE_URL}/member/${id}`
  },
  SPECIALTY: {
    specialtyList: `${API_BASE_URL}/provider/medical-specializations`,
  }


}
