import { Box, Button, Card, CardContent, CardMedia, Chip, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import FamyllIcon from 'src/@core/components/icons/FamyllIcon'
import Translations from 'src/layouts/components/Translations'


const MemberCardDialog = ({ toggle, isOpen, data }) => {

    const [memberDetails, setMemberDetails] = useState(null)

    useEffect(() => {
        if (data)
            setMemberDetails(data)
    }, [data])


    return (
        <>
            <Dialog onClose={toggle} open={isOpen} fullWidth
                maxWidth="sm" PaperProps={{ sx: { borderRadius: '17px', padding: "24px" } }}>
                <DialogTitle variant='fm-h6' color='nutrual-80' fontWeight={600} sx={{ display: "flex", justifyContent: "center" }}>
                    <Translations text="Membership Card" />
                </DialogTitle>
                <DialogContent>

                    <Grid container>
                        <Card sx={{
                            borderRadius: '10px',
                            background: 'linear-gradient(106deg, #814CB4 1.98%, #7338AC 98%)',
                            width: '100%',
                        }}
                        >
                            <CardContent>
                                <CardMedia
                                    component="img"
                                    image='/assets/images/Group.png'  // Replace with the actual path to your logo image
                                    alt="Logo Alt Text"
                                    sx={{ width: '160px', height: '170px', position: 'absolute', bottom: '130px', right: '75px' }}
                                />
                                <Grid item container xs={12} sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                                    <Grid item xs={6}>
                                        <FamyllIcon />
                                    </Grid>
                                    <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Chip label="Covered" sx={{ backgroundColor: '#FFF', color: "#1A9C2F", fontSize: '13px', fontWeight: 500 }} />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} sx={{ display: 'flex', alignItems: 'center', mb: 4 }} spacing={3}>
                                    <Grid item xs={3}>
                                        <Box
                                            sx={{
                                                background: 'url(/assets/images/card.png) lightgray 50% / cover no-repeat',
                                                width: '100px',
                                                height: '100px',
                                                borderRadius: '20px'
                                            }}
                                        >
                                        </Box>
                                    </Grid>

                                    <Grid item xs={9} sx={{ display: 'flex', flexDirection: 'column' }} justifyContent={{ xs: 'flex-end', md: 'flex-start' }}>
                                        <Typography variant={{ md: 'fm-h5', xs: 'fm-p2' }}
                                            sx={{ textTransform: 'uppercase', color: '#FFF' }} color='neutral.80' fontWeight={600}>
                                            {`${memberDetails?.first_name} ${memberDetails?.middle_name} ${memberDetails?.last_name}`}
                                        </Typography>
                                        <Typography variant={{ md: 'fm-h5', xs: 'fm-p2' }}
                                            sx={{ textTransform: 'uppercase', color: '#FFF' }} color='neutral.80' fontWeight={600}>
                                            {memberDetails?.registration_number}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 4 }}>
                                    <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                                        <Typography variant='fm-p3' color='primary.contrastText'>
                                            <Translations text="DOB" />
                                        </Typography>
                                        <Typography variant='fm-p2' color='primary.contrastText' fontWeight={500}>
                                            {moment(memberDetails?.dob).format('DD/MM/YYYY')}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                                        <Typography variant='fm-p3' color='primary.contrastText'>
                                            <Translations text="PLAN OWNER" />
                                        </Typography>
                                        <Typography variant='fm-p2' color='primary.contrastText' fontWeight={500} sx={{ textTransform: 'uppercase' }}>
                                            {memberDetails?.plan_owner_name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                    <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                                        <Typography variant='fm-p3' color='primary.contrastText'>
                                            <Translations text="PLAN EFFECTIVITY" />
                                        </Typography>
                                        <Typography variant='fm-p2' color='primary.contrastText' fontWeight={500}>
                                            <Translations text="26/07/2023" />
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                                        <Typography variant='fm-p3' color='primary.contrastText'>
                                            <Translations text="PLAN TYPE" />
                                        </Typography>
                                        <Typography variant='fm-p2' color='primary.contrastText' fontWeight={500} sx={{ textTransform: 'uppercase' }}>
                                            {memberDetails?.plan_type}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </DialogContent>
                <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
                    {/* <Button variant="contained" sx={{ padding: '12px 24px' }}>
                        <Typography variant='fm-p1' fontWeight={600}>
                            <Translations text="Download Membership Card" />
                        </Typography>
                    </Button> */}
                </DialogContent>
            </Dialog>
        </>
    )
}

export default MemberCardDialog