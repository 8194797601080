import { Avatar, Box, Button, Card, CardContent, Chip, Divider, Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import Translations from 'src/layouts/components/Translations'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import { styled } from '@mui/material/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CircleIcon from '@mui/icons-material/Circle'
import { useTheme } from '@mui/material/styles'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { axiosInstance } from 'src/network/adapter'
import { ApiEndpoints } from 'src/network/endpoints'
import GreenDotIcon from 'src/@core/components/icons/GreenDotIcon'
import MemberCardDialog from 'src/views/dialog/memberCard'
import toast from 'react-hot-toast'
import moment from 'moment'
import { PlanStatus, preExistingConditions } from 'src/utils/constants'
import Loader from 'src/@core/components/loader'
import { Trans } from 'react-i18next'

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    padding: theme.spacing(0, 4),
    minHeight: theme.spacing(12),
    transition: 'min-height 0.15s ease-in-out',

    '&.Mui-expanded': {
        minHeight: theme.spacing(12)
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: '10px 0'
    }
}))

const CustomAccordion = styled(Accordion)(({ theme }) => ({
    padding: theme.spacing(2),
    boxShadow: 'none !important',
    borderBottom:
        theme.palette.mode === 'light' ? `1px solid ${theme.palette.grey[300]}` : `1px solid ${theme.palette.divider}`,
    '&:before': {
        display: 'none'
    },
    '&.Mui-expanded': {
        margin: 'auto'
    },
    '&:first-of-type': {
        '& .MuiButtonBase-root': {
            borderTopLeftRadius: theme.shape.borderRadius,
            borderTopRightRadius: theme.shape.borderRadius
        }
    },
    '&:last-of-type': {
        '& .MuiAccordionSummary-root:not(.Mui-expanded)': {
            borderBottomLeftRadius: theme.shape.borderRadius,
            borderBottomRightRadius: theme.shape.borderRadius
        }
    }
}))

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    // padding: theme.spacing(4),
}))
const MemberDetail = () => {
    const theme = useTheme()
    const { id } = useParams()
    const [memberData, setMemberData] = useState('')
    const conditions = memberData?.pre_existing_condition?.map(condition => condition.condition_name).join(', ');
    const [isMemberDialogOpen, setIsMemberDialogOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [memberDetails, setMemberDetails] = useState([])

    const toggleMemberCardDialog = () => {
        setIsMemberDialogOpen(prev => !prev)
    }
    const navigate = useNavigate()
    const editMember = () => {
        navigate('#')
    }
    const UpdateCoveragePlan = () => {
        navigate('/portfolio/update-plan')
    }

    const getMemberDetails = (id) => {
        setLoading(true);
        axiosInstance
            .get(ApiEndpoints.MEMBERS.memberDetails(id))
            .then((response) => response?.data?.data)
            .then((response) => {
                setMemberDetails(response?.member)
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        getMemberDetails(id)
    }, [id])


    const getConditionLabel = (conditionValue) => {
        const condition = preExistingConditions.find(item => item.value === conditionValue);
        return condition ? condition.label : ''; // Return label if condition is found, otherwise an empty string
    };

    const planStatus = PlanStatus.find(status => status.value === memberDetails?.coverage_plan_status);

    // If no matching status, set default values
    const label = planStatus ? planStatus.label : 'Unknown';
    const color = planStatus ? planStatus.color : 'grey';
    const bGColor = planStatus ? planStatus.bg : 'grey';

    return (
        <>
            {loading ? <Loader /> :
                <Card>
                    <CardContent>
                        <Box>
                            <Typography variant='fm-h6' fontWeight={600}>
                                <Translations text="Member" />  {memberDetails?.registration_number}
                            </Typography>
                        </Box>
                        <Divider sx={{ mt: 5 }} />
                        <Grid container spacing={6} sx={{ mt: 0 }}>
                            <Grid
                                item
                                xs={12}
                                md={8}
                                sx={{
                                    display: 'flex',
                                    width: '100%',
                                    gap: '16px'
                                }}
                            >
                                <Avatar
                                    src={`${memberDetails?.profile_image}`}
                                    variant='rounded'
                                    sx={{ width: 150, height: 150, borderRadius: '20px' }}
                                />
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                    <Box sx={{ display: 'flex', gap: "10px" }}>
                                        <Typography variant="fm-h5" sx={{ fontWeight: 600, color: "#000000" }}>
                                            {`${memberDetails?.first_name} ${memberDetails?.middle_name} ${memberDetails?.last_name}`}
                                        </Typography>
                                        <Chip label={label} sx={{ backgroundColor: bGColor, color: color, fontSize: '16px', fontWeight: 500 }} avatar={<GreenDotIcon color={color} />} />
                                    </Box>
                                    <Typography variant='fm-p2' color='#8B7F97' fontWeight={500} sx={{ display: 'flex', gap: '8px' }}>
                                        <Translations text={'Gender'} />:
                                        <Typography variant='fm-p2' color='neutral.80' fontWeight={500} sx={{ textTransform: 'capitalize' }}>
                                            {memberDetails?.gender}
                                        </Typography>
                                    </Typography>

                                    <Typography variant='fm-p2' color='#8B7F97' fontWeight={500} sx={{ display: 'flex', gap: '8px' }}>
                                        <Translations text={'DOB'} />:
                                        <Typography variant='fm-p2' color='neutral.80' fontWeight={500}>
                                            {moment(memberDetails?.dob).format('DD/MM/YYYY')}
                                        </Typography>
                                    </Typography>

                                    <Typography variant='fm-p2' color='#8B7F97' fontWeight={500} sx={{ display: 'flex', gap: '8px' }}>
                                        <Translations text={'Relation'} />
                                        <Typography variant='fm-p2' color='neutral.80' fontWeight={500} sx={{ textTransform: 'capitalize' }}>
                                            {memberDetails?.relationship}
                                        </Typography>
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', gap: 5 }}>
                                <Button variant='contained' sx={{ padding: '10px 20px 10px 20px' }} onClick={toggleMemberCardDialog}>
                                    <Typography variant='fm-p3' fontWeight={600}>
                                        <Translations text='Membership Card' />
                                    </Typography>
                                </Button>
                                <Link to="/provider/submit-claim">
                                    <Button sx={{ padding: '10px 20px 10px 20px', bgcolor: "#EBF3F7", color: '#000000' }}>
                                        <Typography variant='fm-p3' fontWeight={600}>
                                            <Translations text='Submit Claim' />
                                        </Typography>
                                    </Button>
                                </Link>
                            </Grid>

                            <Grid item xs={12}>
                                <Divider mt={0} />
                                <CustomAccordion defaultExpanded>
                                    <CustomAccordionSummary
                                        expandIcon={<ExpandMoreIcon sx={{ fontSize: '35px', color: 'neutral.80', fontWeight: '400' }} />}
                                        aria-controls='panel1-content'
                                        id='panel1-header'
                                    >
                                        <Typography variant='fm-h6' fontWeight={600} sx={{ color: 'neutral.80' }}>
                                            <Translations text={`Contact Information`} />
                                        </Typography>
                                    </CustomAccordionSummary>

                                    <CustomAccordionDetails sx={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)' }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                            <Typography variant='fm-p3' color='#8B7F97' fontWeight={500}>
                                                <Translations text={`Phone Number`} />
                                            </Typography>
                                            <Typography variant='fm-p2' color='neutral.80' fontWeight={500}>
                                                {memberDetails?.phone_number}
                                            </Typography>
                                        </Box>

                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                            <Typography variant='fm-p3' color='#8B7F97' fontWeight={500}>
                                                <Translations text={`Email Address`} />
                                            </Typography>
                                            <Typography variant='fm-p2' color='neutral.80' fontWeight={500}>
                                                {memberDetails?.email}
                                            </Typography>
                                        </Box>

                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                            <Typography variant='fm-p3' color='#8B7F97' fontWeight={500}>
                                                <Translations text={`City`} />
                                            </Typography>
                                            <Typography variant='fm-p2' color='neutral.80' fontWeight={500} sx={{ textTransform: 'capitalize' }}>
                                                {memberDetails?.city}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                            <Typography variant='fm-p3' color='#8B7F97' fontWeight={500}>
                                                <Translations text={`State/Province`} />
                                            </Typography>
                                            <Typography variant='fm-p2' color='neutral.80' fontWeight={500} sx={{ textTransform: 'capitalize' }}>
                                                {memberDetails?.state}
                                            </Typography>
                                        </Box>

                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                            <Typography variant='fm-p3' color='#8B7F97' fontWeight={500}>
                                                <Translations text={`Country`} />
                                            </Typography>
                                            <Typography variant='fm-p2' color='neutral.80' fontWeight={500} sx={{ textTransform: 'capitalize' }}>
                                                {memberDetails?.country}
                                            </Typography>
                                        </Box>
                                    </CustomAccordionDetails>
                                </CustomAccordion>

                                <CustomAccordion>
                                    <CustomAccordionSummary
                                        expandIcon={<ExpandMoreIcon sx={{ fontSize: '35px', color: 'neutral.80' }} />}
                                        aria-controls='panel1-content'
                                        id='panel1-header'
                                    >
                                        <Typography variant='fm-h6' fontWeight={600}>
                                            <Translations text={'Pre-existing Condition'} />
                                        </Typography>
                                    </CustomAccordionSummary>

                                    <CustomAccordionDetails sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
                                        <Box sx={{ display: 'flex', flexDirection: "column", gap: "8px" }}>
                                            <Box sx={{ display: 'flex', }}>
                                                <Typography variant='fm-p2' color='neutral.80' fontWeight={400} sx={{ textTransform: 'capitalize' }}>
                                                    {memberDetails?.pre_existing_condition?.map((condition) => getConditionLabel(condition.condition_name)).join(', ')}
                                                </Typography>
                                            </Box>

                                            <Box sx={{ display: 'flex', gap: 15 }}>
                                                <Typography variant='fm-p2' color='#8B7F97' fontWeight={600} sx={{ display: 'flex', gap: '8px' }}>
                                                    <Translations text={'Smoker'} />:
                                                    <Typography variant='fm-p2' color='neutral.80' fontWeight={500} sx={{ textTransform: 'capitalize' }}>
                                                        {memberDetails.smoker}
                                                    </Typography>
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </CustomAccordionDetails>
                                </CustomAccordion>

                                <CustomAccordion>
                                    <CustomAccordionSummary
                                        expandIcon={<ExpandMoreIcon sx={{ fontSize: '35px', color: 'neutral.80' }} />}
                                        aria-controls='panel1-content'
                                        id='panel1-header'
                                    >
                                        <Typography variant='fm-h6' fontWeight={600}>
                                            <Translations text={'Plan Details'} />
                                        </Typography>
                                    </CustomAccordionSummary>

                                    <CustomAccordionDetails sx={{ display: '', gridTemplateColumns: 'repeat(3, 1fr)' }}>
                                        <Card sx={{ borderRadius: '12px', mt: 5, backgroundColor: '#F5F4F9' }}>
                                            <CardContent>
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Typography gutterBottom variant='fm-h5' component='div' fontWeight={500}>
                                                        <Translations text={'Basic Plan'} />
                                                    </Typography>
                                                </Box>

                                                <Grid container spacing={6}>
                                                    {memberDetails?.plan_details?.length > 0 && memberDetails?.plan_details.map((detail) => {
                                                        return (
                                                            <Grid item xs={12} md={6} key={detail?.title}>
                                                                <CircleIcon sx={{ fontSize: '12px', color: theme.palette.primary.main }} />{' '}
                                                                <Typography variant='fm-p2' color='neutral.90'>
                                                                    {detail?.title}
                                                                </Typography>
                                                                <Grid container sx={{ display: 'flex', flexDirection: 'column', gap: 3, marginTop: '10px', marginLeft: '16px' }}>
                                                                    <Grid item>
                                                                        <Typography variant='fm-p2' color='#8B7F97' sx={{ display: 'flex', gap: '8px' }}>
                                                                            <Translations text="AvailableVisit" />
                                                                            <Typography>: {detail?.visit}</Typography>
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography variant='fm-p2' color='#8B7F97' sx={{ display: 'flex', gap: '8px' }}>
                                                                            <Translations text="CoveredAmount" />
                                                                            <Typography>: 0</Typography>
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography variant='fm-p2' color='#8B7F97' sx={{ display: 'flex', gap: '8px' }}>
                                                                            <Translations text="AmountUsed" />
                                                                            <Typography>: 0</Typography>
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography variant='fm-p2' color='#8B7F97' sx={{ display: 'flex', gap: '8px' }}>
                                                                            <Translations text="Balance" />
                                                                            <Typography>: 0</Typography>
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        );
                                                    })}
                                                </Grid>
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
                                                    <Typography gutterBottom variant='fm-h6' fontWeight={500} color='neutral.90'>
                                                        <Translations text="Plan Description" />
                                                    </Typography>
                                                    {/* <Typography gutterBottom variant='fm-h6' fontWeight={500} color='neutral.90'>
                                                    50%
                                                </Typography> */}
                                                </Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: "10px" }}>
                                                    <Typography variant='fm-p2' color='#7E7585' fontWeight={400}>{memberDetails?.bullet_plan_description}</Typography>
                                                    <Typography variant='fm-p2' color='#7E7585' fontWeight={400}>
                                                        {memberDetails?.extra_services_description}
                                                    </Typography>
                                                </Box>
                                                <Divider />
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                                                    <Typography gutterBottom variant='fm-h6' fontWeight={500} color='neutral.90'>
                                                        <Translations text={'Total Plan Value'} />
                                                    </Typography>
                                                    <Typography gutterBottom variant='fm-h6' fontWeight={500} color='neutral.90'>
                                                        ${memberDetails?.total_amount}
                                                    </Typography>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                        <Card sx={{ borderRadius: '12px', mt: 5, backgroundColor: '#F5F4F9' }}>
                                            <CardContent>
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: "10px" }}>
                                                    <Typography gutterBottom variant='fm-h5' fontWeight={500} color='neutral.80'>
                                                        <Translations text={'My Care Team'} />
                                                    </Typography>
                                                </Box>

                                                <Grid container spacing={6}>
                                                    {memberDetails?.provider?.length > 0 && memberDetails?.provider.map((item) => (
                                                        <Grid item xs={12} md={6} key={item.provider_name}>
                                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                                <CircleIcon sx={{ fontSize: '12px', color: theme.palette.primary.main }} />
                                                                <Typography variant='fm-p2' color='neutral.90' sx={{ textTransform: 'capitalize' }}>
                                                                    {item.provider_name}
                                                                </Typography>
                                                            </Box>
                                                            <Typography sx={{ marginLeft: '20px', textTransform: 'capitalize' }}>
                                                                {item?.provider_type}
                                                            </Typography>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </CustomAccordionDetails>
                                </CustomAccordion>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            }
            <MemberCardDialog
                isOpen={isMemberDialogOpen}
                toggle={toggleMemberCardDialog} data={memberDetails} />
        </>
    )
}

export default MemberDetail