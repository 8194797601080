import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { Box, Card, CardContent, Typography } from "@mui/material";
import useCustomTranslation from 'src/@core/hooks/useCustomTranslation';
import { useAuth } from 'src/hooks/useAuth';
function LogoutDialog({ open, toggle }) {
    const translation = useCustomTranslation()
    const auth = useAuth();

    return (
        <Dialog
            fullWidth
            maxWidth={'xs'}
            open={open}
            onClose={toggle}
            PaperProps={{ sx: { borderRadius: '24px' } }}
        >
            <Card sx={{ height: "200px" }}>
                <CardContent>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "10px" }}>
                        <Typography variant='fm-h6' fontWeight={600}>
                            {"Are you sure you want to Logout?"}
                        </Typography>
                    </Box>

                    <DialogActions sx={{ width: '100%', justifyContent: 'center', gap: '10px', marginTop: "20px" }}>
                        <Button
                            onClick={(e) => {
                                auth.logout()
                            }}
                            variant='contained'
                            sx={{
                                width: "100%",
                                padding: '12px 30px 12px 30px',
                                textTransform: 'capitalize',
                            }}
                        >
                            <Typography variant='fm-p3' fontWeight={600}>{translation.translate('Yes')}</Typography>
                        </Button>
                        <Button onClick={toggle}
                            variant='outlined'
                            sx={{

                                width: "100%",
                                padding: '12px 30px 12px 30px',
                                textTransform: 'capitalize',
                            }}
                        >
                            <Typography variant='fm-p3' fontWeight={600}>{translation.translate('No')}</Typography>
                        </Button>
                    </DialogActions>
                </CardContent>
            </Card>
        </Dialog>
    )
}
export default LogoutDialog