// ** MUI Imports
import { styled, useTheme } from '@mui/material/styles'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import MuiAppBar from '@mui/material/AppBar'
import MuiToolbar from '@mui/material/Toolbar'
import { Link, useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu';
// ** Util Import
import { hexToRGBA } from 'src/@core/utils/hex-to-rgba'
import { Box, Container, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import NotificationDropdown from '../../shared-components/NotificationDropdown'
import Translations from 'src/layouts/components/Translations'
import { useEffect } from 'react'


const LayoutAppBar = props => {
  // ** Props
  const { settings, verticalAppBarContent: userVerticalAppBarContent, hidden, toggleNavVisibility } = props

  // ** Hooks
  const location = useLocation()
  const navigate = useNavigate()

  // ** Vars
  const { appBar } = settings

  if (appBar === 'hidden') {
    return null
  }
  const goBack = () => {
    navigate(-1)
  }
  const openSearchPage = () => {
    if (location.pathname !== '/search') {
      navigate('/search');
    }
  };

  return (
    <>
      <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: "26px", paddingRight: "26px", paddingTop: "10px" }}>
        <Box sx={{ display: { xs: 'none', lg: 'flex' } }}>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
            {(location.pathname !== '/provider' && location.pathname !== '/bonus-incentive' && location.pathname !== '/my/profile' && location.pathname !== '/my/reimbursements' && location.pathname !== '/search' && location.pathname !== '/member-detail') && (<Box>
              <IconButton sx={{ backgroundColor: '#7338AC' }} onClick={goBack}>
                <ArrowBackIosNewRoundedIcon fontSize='small' sx={{ color: '#ffffff' }} />
              </IconButton>
            </Box>
            )}
            <Box>
              {location.pathname === '/provider' && (
                <Typography variant='fm-h5' fontWeight={600} color='neutral.80'>
                  <Translations text='Member' />
                </Typography>
              )}
              {location.pathname === '/my/profile/change-password' && (
                <Typography variant='fm-h5' fontWeight={600} color='neutral.80'>
                  <Translations text='Change Password' />
                </Typography>
              )}
              {location.pathname === '/my/profile' && (
                <Typography variant='fm-h5' fontWeight={600} color='neutral.80'>
                  <Translations text='My Profile' />
                </Typography>
              )}
              {location.pathname === '/my/reimbursements' && (
                <Typography variant='fm-h5' fontWeight={600} color='neutral.80'>
                  <Translations text='My Reimbursements' />
                </Typography>
              )}
              {location.pathname === '/bonus-incentive' && (
                <Typography variant='fm-h5' fontWeight={600} color='neutral.80'>
                  <Translations text='Bonus Incentive' />
                </Typography>
              )}
              {location.pathname.includes('/provider/member-detail/') && (
                <Typography variant='fm-h5' fontWeight={600} color='neutral.80'>
                  <Translations text='Member Details' />
                </Typography>
              )}
              {location.pathname === '/provider/submit-claim' && (
                <Typography variant='fm-h5' fontWeight={600} color='neutral.80'>
                  <Translations text='Submit claim' />
                </Typography>
              )}
              {location.pathname === '/my/profile/edit-profile' && (
                <Typography variant='fm-h5' fontWeight={600} color='neutral.80'>
                  <Translations text='Edit Profile' />
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
        <Box className='actions-left' sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
          {hidden ? (
            <IconButton color='inherit' sx={{ ml: -2.75 }} onClick={toggleNavVisibility}>
              <MenuIcon />
            </IconButton>
          ) : null}
          {/* <Autocomplete hidden={hidden} settings={settings} /> */}
        </Box>
        <Box className='actions-right' sx={{ display: 'flex', alignItems: 'center', gap: 2, width: location.pathname === '/search' ? '100%' : 'auto', justifyContent: location.pathname !== '/search' ? 'flex-end' : 'auto' }}>
          {/* <LanguageDropdown settings={settings} saveSettings={saveSettings} /> */}
          {/* <ModeToggler settings={settings} saveSettings={saveSettings} /> */}

          <Box sx={{
            width: location.pathname === '/search' ? '100%' : 'auto'
          }}>
            <TextField
              type='search'
              size='small'
              fullWidth={location.pathname === '/search'}
              // value={search}
              placeholder='Search here'
              // onChange={e => setSearch(e.target.value)}
              // onChange={handleSearchChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton>
                      <img
                        src='/assets/images/search-normal.png'
                        alt='Search'
                        style={{ width: '24px', height: '24px' }}
                      />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              onClick={openSearchPage}
            />
          </Box>
          <NotificationDropdown settings={settings} />
          {/* <UserDropdown settings={settings} /> */}
        </Box>
      </Box>
    </>
    // <AppBar elevation={0} color='default' className='layout-navbar' position={appBar === 'fixed' ? 'sticky' : 'static'}>
    //   <Toolbar
    //     className='navbar-content-container'
    //     sx={{
    //       ...(appBar === 'fixed' && scrollTrigger && { ...appBarFixedStyles() }),
    //       ...(contentWidth === 'boxed' && {
    //         '@media (min-width:1440px)': { maxWidth: `calc(1440px - ${theme.spacing(6)} * 2)` }
    //       })
    //     }}
    //   >
    //     {(userVerticalAppBarContent && userVerticalAppBarContent(props)) || null}
    //   </Toolbar>
    // </AppBar>
  )
}

export default LayoutAppBar
